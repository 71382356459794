<template>
    <modal ref="modalDetallePago" titulo="Detalle del pago mercado pago" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col">
                <div class="row mx-0 mt-2 text-general f-15">
                    <div class="col pl-0 f-500">
                        <img src="/img/icons/pago_online.svg" width="40" height="50" class="mr-1" />
                        {{ separadorNumero(basicos.value) }}
                    </div>
                    <div class="col">
                        <div :class="`${basicos.state_pol == 6 || basicos.state_pol == 5 || basicos.state_pol == 9 ? 'bg-general text-white' : basicos.state_pol == 4 ? 'bg-success text-white' : 'bg-light-f5 border text-general2'}  f-14 px-2 br-4 text-center rounded-pill`" style="width:90px;">
                            <p v-text="basicos.state_pol == 6 || basicos.state_pol == 5 || basicos.state_pol == 9 ? 'Rechazado' : basicos.state_pol == 4 ? 'Aprobado' : 'Pendiente'" />
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-2 text-general f-15">
                    <div class="col pl-0 f-500">
                        Usuario
                    </div>
                    <div class="col">
                        {{ basicos.usuario }}
                    </div>
                </div>
                <div class="row mx-0 mt-2 text-general f-15">
                    <div class="col pl-0 f-500">
                        Origen del pago
                    </div>
                    <div class="col">
                        {{ origen }}
                    </div>
                </div>
                <div class="row mx-0 mt-2 text-general f-15">
                    <div class="col pl-0 f-500">
                        Referencia
                    </div>
                    <div class="col">
                        {{ basicos.reference_sale }}
                    </div>
                </div>
                <div class="row mx-0 mt-2 text-general f-15">
                    <div class="col pl-0 f-500">
                        Fecha y hora del pago
                    </div>
                    <div class="col">
                        {{ basicos.created_at | helper-fecha('DD/MM/Y - hh:mm a') }}
                    </div>
                </div>
                <div class="row mx-0 mt-2 text-general f-15">
                    <div class="col pl-0 f-500">
                        Medio de pago
                    </div>
                    <div class="col">
                        {{ basicos.payment_method_name }}
                    </div>
                </div>
                <div class="row mx-0 mt-2 text-general f-15">
                    <div class="col pl-0 f-500">
                        Motivo Rechazo
                    </div>
                    <div class="col">
                        {{ basicos.response_message_pol }}
                    </div>
                </div>
                <div v-show="basicos.state_pol == 4 || basicos.state_pol == 6" class="row mx-0 my-3 text-general f-14 fr-light" @click="getDetalle">
                    <div class="col-auto w-235px d-middle f-500 border br-10 bg-whitesmoke2 text-center cr-pointer">
                        {{ verMas ? 'Ver menos detalle del pago' : 'Ver más detalle del pago' }}
						<i :class="`${verMas ? 'icon-angle-up' : 'icon-angle-down'}`"></i>
                    </div>
                </div>
                <div v-show="verMas">
                    <div v-show="pago.merchant_id" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            merchant_id
                        </div>
                        <div class="col">
                            {{ pago.merchant_id }}
                        </div>
                    </div>
                    <div v-show="pago.state_pol" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            state_pol
                        </div>
                        <div class="col">
                            {{ pago.state_pol }}
                        </div>
                    </div>
                    <div v-show="pago.response_code_pol" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            response_code_pol
                        </div>
                        <div class="col">
                            {{ pago.response_code_pol }}
                        </div>
                    </div>
                    <div v-show="pago.response_message_pol" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            response_message_pol
                        </div>
                        <div class="col">
                            {{ pago.response_message_pol }}
                        </div>
                    </div>
                    <div v-show="pago.descripcion" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            descripcion
                        </div>
                        <div class="col">
                            {{ pago.descripcion }}
                        </div>
                    </div>
                    <div v-show="pago.extra1" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            extra1
                        </div>
                        <div class="col">
                            {{ pago.extra1 }}
                        </div>
                    </div>
                    <div v-show="pago.risk" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            risk
                        </div>
                        <div class="col">
                            {{ pago.risk }}
                        </div>
                    </div>
                    <div v-show="pago.sign" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            sign
                        </div>
                        <div class="col">
                            {{ pago.sign }}
                        </div>
                    </div>
                    <div v-show="pago.transaction_id" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            transaction_id
                        </div>
                        <div class="col">
                            {{ pago.transaction_id }}
                        </div>
                    </div>
                    <div v-show="pago.payment_method" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            payment_method
                        </div>
                        <div class="col">
                            {{ pago.payment_method }}
                        </div>
                    </div>
                    <div v-show="pago.payment_method_type" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            payment_method_type
                        </div>
                        <div class="col">
                            {{ pago.payment_method_type }}
                        </div>
                    </div>
                    <div v-show="pago.payment_method_name" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            payment_method_name
                        </div>
                        <div class="col">
                            {{ pago.payment_method_name }}
                        </div>
                    </div>
                    <div v-show="pago.installments_number" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            installments_number
                        </div>
                        <div class="col">
                            {{ pago.installments_number }}
                        </div>
                    </div>
                    <div v-show="pago.tax" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            tax
                        </div>
                        <div class="col">
                            {{ pago.tax }}
                        </div>
                    </div>
                    <div v-show="pago.administrative_fee" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            administrative_fee
                        </div>
                        <div class="col">
                            {{ pago.administrative_fee }}
                        </div>
                    </div>
                    <div v-show="pago.administrative_fee_tax" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            administrative_fee_tax
                        </div>
                        <div class="col">
                            {{ pago.administrative_fee_tax }}
                        </div>
                    </div>
                    <div v-show="pago.transaction_date" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            transaction_date
                        </div>
                        <div class="col">
                            {{ pago.transaction_date }}
                        </div>
                    </div>
                    <div v-show="pago.email_buyer" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            email_buyer
                        </div>
                        <div class="col">
                            {{ pago.email_buyer }}
                        </div>
                    </div>
                    <div v-show="pago.currency" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            currency
                        </div>
                        <div class="col">
                            {{ pago.currency }}
                        </div>
                    </div>
                    <div v-show="pago.cus" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            cus
                        </div>
                        <div class="col">
                            {{ pago.cus }}
                        </div>
                    </div>
                    <div v-show="pago.pse_bank" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            pse_bank
                        </div>
                        <div class="col">
                            {{ pago.pse_bank }}
                        </div>
                    </div>
                    <div v-show="pago.ip" class="row mx-0 mt-2 text-general f-15">
                        <div class="col pl-0 f-500">
                            ip
                        </div>
                        <div class="col">
                            {{ pago.ip }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Pagos from "~/services/pasarela-tesoreria/pagos"
export default {
    data(){
        return {
            basicos:{},
            pago:{},
            verMas:false
        }
    },
    computed:{
        origen(){
            if(this.basicos.id_entrega) return 'Entrega Dineros'
            if(this.basicos.cliente) return 'Pedido de Cliente'
            return 'Pedido de Vendedor'
        }
    },
    methods: {
        toggle(row){
            this.verMas = false
            this.basicos = row
            this.$refs.modalDetallePago.toggle();
        },
        async getDetalle(){
            try {
                if(this.verMas){
                    this.verMas = false
                    return
                }
                this.verMas = true
                const {data} = await Pagos.detallePago(this.basicos.id)
                this.pago = data.pago

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
